import React from "react";
import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import FMBlogo from "../../assests/FMBlogo.png";
import img1 from "../../assests/Potrait/1.png";
import { Link, useNavigate } from "react-router-dom";

const QueuePage = () => {
  return (
    <Box sx={{ maxWidth: 400, mx: "auto", textAlign: "center", p: 2 }}>
      <Typography variant="h5" fontWeight="bold">
         <img src={FMBlogo} width={300} />
      </Typography>
      <Typography variant="h6" mt={1}>
        Ditt kønummer er
      </Typography>
      <Typography variant="h1" fontWeight="bold" mt={2}>
        011
      </Typography>

      <Card variant="outlined" sx={{ my: 2,backgroundColor: "#f5f5f5" }}>
        <CardContent>
          <Typography variant="h6" fontWeight="bold">
            Mal Proff
          </Typography>
          <Typography variant="body2">
            Spør oss om aktuelle kampanjer og tilbud.
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ my: 2,backgroundColor: "#f5f5f5"  }}>
        <CardContent>
          <Typography variant="h6" fontWeight="bold">
            Tapet / Gardiner / Solskjerming
          </Typography>
          <Typography variant="body2">
            Spør oss gjerne om aktuelle kampanjer og tilbud, samt at vi har en del varer på lager til god priser.
          </Typography>
        </CardContent>
      </Card>

      <Box sx={{ mt: 4 }}>
        <img
          src={img1}
          alt="Cleaning service"
          style={{ width: "100%", borderRadius: 8 }}
        />
        {/* <Typography variant="h6" fontWeight="bold" mt={2}>
          Spør oss om leie av tepperensemaskin
        </Typography>
        <Typography variant="body2" mt={1}>
          Vi tilbyr flere produkter til rengjøring av tepper og møbler i private hjem.
        </Typography> */}
      </Box>

      <Typography variant="body1" color="primary" fontWeight="bold" mt={4}>
      <Link>easyQue</Link> (c) by Digifront
      </Typography>
      <Typography variant="body2" color="primary" component="a" href="mailto:sales@digifront.biz">
        sales@digifront.biz
      </Typography>
    </Box>
  );
};

export default QueuePage;
