import React, { useState, useEffect } from "react";
import FMBLogo from "../../../assests/FMBlogo.png"; // Import the image

// Dynamically import all images in the folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
    require.context(
        "../../../assests/landscape",
        false,
        /\.(png|jpg|jpeg|webp)$/
    )
);

const DisplayAd = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval);
    }, []);
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column", // Stack items vertically
                    height: "100vh", // Full viewport height to center everything
                }}
            >
                <img
                    src={FMBLogo} // Use the imported image path
                    alt="FMB Logo"
                    style={{
                        width: "28%",
                        transition: "opacity 1s ease-in-out",
                        marginBottom: "50px", // Space between the image and the number
                    }}
                />
                <div
                    style={{
                        fontSize: "140px", // Larger font size
                        marginTop: "-80px",
                    }}
                >
                    <h1>15</h1> {/* The token number */}
                </div>
            </div>

            <img
                src={images[currentIndex]}
                alt={`Landscape ${currentIndex + 1}`}
                style={{ width: "90%", transition: "opacity 1s ease-in-out" }}
            />
        </div>
    );
};

export default DisplayAd;
