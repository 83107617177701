import * as React from "react";
import { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import ReportCard from "./ReportCard";
import ReportContent from "./ReportContent";

const Index = () => {
    const [selectedReport, setSelectedReport] = useState(null);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ textAlign: "center", mb: 2 }}>
                <Typography sx={{ fontSize: 40, fontWeight: "bold" }}>Reports</Typography>
            </Box>

            {/* Report Cards */}
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ReportCard title="Average Customer Count" onClick={() => setSelectedReport("averageCustomerCount")} />
                </Grid>
                <Grid item xs={4}>
                    <ReportCard title="Customer Count (2 Hours Slot)" onClick={() => setSelectedReport("averageCustomerTimeSlot")} />
                </Grid>
                <Grid item xs={4}>
                    <ReportCard title="Average Waiting Times (Minutes)" onClick={() => setSelectedReport("averageWaitingTime")} />
                </Grid>
            </Grid>

            {/* Report Content */}
            <ReportContent selectedReport={selectedReport} />
        </Box>
    );
};

export default Index;
