import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Admin/Login";
import AdminHome from "./pages/Admin/Home";
import Landscape from "./pages/Admin/DisplayOption/Landscape";
import Potrait from "./pages/Admin/DisplayOption/Potrait";
import Carousel_Portrait from "./pages/Admin/DisplayOption/Carousel_Portrait";
import Carousel_landscape from "./pages/Admin/DisplayOption/Carousel_landscape";
import Carousel from "./pages/Admin/DisplayOption/Carousel";
import Staff from "./pages/Admin/Staff/index";
import Display from "./pages/Admin/QueueNr/Display";
import DisplayAd from "./pages/Admin/QueueNr/DisplayAd";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import VerifyAdmin from "./pages/Admin/VerifyAdmin";
import CustomerView from "./pages/Admin/CustomerView/index";
import NextCustomerView from "./pages/Admin/CustomerView/NextCustomerView";
import User from "./pages/User/index";


function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        setIsAuthenticated(!!localStorage.getItem("token"));
    }, []);

    const protectedRoutes = [
        { path: "/home/*", element: <AdminHome /> },
        { path: "/home/display_ad_Landscape", element: <Landscape /> },
        { path: "/home/display_ad_potrait", element: <Potrait /> },
        { path: "/home/display_ad_carousel", element: <Carousel /> },
        { path: "/home/display_ad_Carousel_landscape", element: <Carousel_landscape /> },
        { path: "/home/display_ad_Carousel_Portrait", element: <Carousel_Portrait /> },
        { path: "/home/display", element: <Display /> },
        { path: "/home/display_ad", element: <DisplayAd /> },
        { path: "/home/customer-view", element: <CustomerView /> },
        { path: "/home/Next_customer-view", element: <NextCustomerView /> },
        { path: "/home/staff", element: <Staff /> },
        { path: "/home/user", element: <User /> }
    ];

    return (
        <Router>
            <Routes>

                <Route path="/" element={<Login />} />
                <Route path="/verify-admin" element={<VerifyAdmin />} />
                {protectedRoutes.map(({ path, element }, index) => (
                    <Route
                        key={index}
                        path={path}
                        element={<ProtectedRoute isAuthenticated={isAuthenticated}>{element}</ProtectedRoute>}
                    />
                ))}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;
