import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { styled } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from "@mui/material";

// ✅ Styled Table Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: theme.palette.action.hover,
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
    },
}));

// ✅ Sample Data (Replace with API Data)
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const waitingTimes = [42, 13, 10, 11, 10, 10, 13];

const Customer_Mintues_Report = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        // ✅ Define Chart Options
        const options = {
            title: {
                text: "Average Waiting Times in Minutes",
                left: "center",
                textStyle: { fontSize: 18, fontWeight: "bold" },
            },
            tooltip: { trigger: "axis" },
            legend: { bottom: 0 },
            xAxis: {
                type: "category",
                data: days,
            },
            yAxis: { type: "value" },
            series: [
                {
                    name: "Average Waiting Time (minutes)",
                    type: "bar",
                    data: waitingTimes,
                    itemStyle: {
                        color: (params) => [
                            "rgba(255, 99, 132, 0.3)",
                            "rgba(54, 162, 235, 0.3)",
                            "rgba(255, 206, 86, 0.3)",
                            "rgba(75, 192, 192, 0.3)",
                            "rgba(153, 102, 255, 0.3)",
                            "rgba(255, 159, 64, 0.3)",
                            "rgba(200, 200, 200, 0.3)",
                        ][params.dataIndex],
                        borderColor: (params) => [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                            "rgba(255, 159, 64, 1)",
                            "rgba(200, 200, 200, 1)",
                        ][params.dataIndex],
                        borderWidth: 2,
                    },
                },
            ],
        };

        // ✅ Render Chart
        chartInstance.setOption(options);

        return () => chartInstance.dispose();
    }, []);

    return (
        <Box sx={{ p: 3 }}>
            {/* ✅ Table Section */}
            <Typography variant="h5" align="center" sx={{ fontWeight: "bold", mb: 2 }}>
                Average Waiting Times in Minutes
            </Typography>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Day</StyledTableCell>
                            {days.map((day) => (
                                <StyledTableCell key={day}>{day}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell>Average Waiting Time (minutes)</StyledTableCell>
                            {waitingTimes.map((time, index) => (
                                <StyledTableCell key={index}>{time}</StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ✅ Chart Section */}
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Average Waiting Time Trend
            </Typography>
            <Box
                ref={chartRef}
                sx={{
                    width: "100%",
                    height: "400px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: 2,
                    backgroundColor: "#fff",
                }}
            />
        </Box>
    );
};

export default Customer_Mintues_Report;
