import React from "react";
import Box from "@mui/material/Box";
import Customer_Day_Report from "./Customer_Day_Report";
import Customer_Hour_Report from "./Customer_Hour_Report";
import Customer_Mintues_Report from "./Customer_Mintues_Report";

const reportComponents = {
    averageCustomerCount: Customer_Day_Report,
    averageCustomerTimeSlot: Customer_Hour_Report,
    averageWaitingTime: Customer_Mintues_Report,
};

const ReportContent = ({ selectedReport }) => {
    console.log(selectedReport, "selectedReport"); // Debugging to see if it updates
    const SelectedComponent = reportComponents[selectedReport];

    return (
        <Box sx={{ p: 2 }}>{SelectedComponent && <SelectedComponent />}</Box>
    );
};

export default ReportContent;
