import React, { useState, useEffect } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Box,
    Menu,
    MenuItem,
    IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Panel from "./Panel";
import Report from "../Report";
import Qr from "./Qr/index"

const AdminHome = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Separate dropdown menu state for Display Options and Queue NR
    const [anchorElDisplay, setAnchorElDisplay] = useState(null);
    const [anchorElQueue, setAnchorElQueue] = useState(null);

    const handleMenuOpenDisplay = (event) => {
        setAnchorElDisplay(event.currentTarget);
    };

    const handleMenuOpenQueue = (event) => {
        setAnchorElQueue(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorElDisplay(null);
        setAnchorElQueue(null);
    };

    const handleDropdownClick = (path) => {
        window.open(path, "_blank"); // Open in new tab
        handleMenuClose();
    };

    const handleDropdownClickQueueNR = (path) => {
        window.open(path, "_blank"); // Open in new tab for Queue NR options
        handleMenuClose();
    };

    // Mapping tabs
    const tabs = [
        { label: "Staff", path: "/home/staff", newTab: true },
        { label: "Panel", path: "/home/panel", component: <Panel /> },
        { label: "Report", path: "/home/report", component: <Report /> },
        {
            label: "Display Options",
            dropdown: true,
            options: [
                { label: "LANDSCAPE", path: "/home/display_ad_Landscape" },
                { label: "POTRAIT", path: "/home/display_ad_potrait" },
                { label: "CAROUSEL", path: "/home/display_ad_carousel" },
                {
                    label: "CAROUSEL LANDSCAPE",
                    path: "/home/display_ad_Carousel_landscape",
                },
                {
                    label: "CAROUSEL PORTRAIT",
                    path: "/home/display_ad_Carousel_Portrait",
                },
            ],
        },
        {
            label: "Queue NR DISPLAY OPTIONS",
            dropdown: true,  // Added dropdown key for Queue NR
            options: [
                { label: "DISPLAY", path: "/home/display" },
                { label: "DISPLAY AD", path: "/home/display_ad" }

            ],
        },
        {
            label: "QR",
            path: "/home/qr",
            component: <Qr />,
        },
        {
            label: "Customer View",
            path: "/home/customer-view",
            component: <Typography>Customer View Content</Typography>,
        },
    ];

    // Get active tab index from URL
    const activeTabIndex = tabs.findIndex(
        (tab) => tab.path === location.pathname
    );

    useEffect(() => {
        if (activeTabIndex === -1) {
            navigate("/home/panel", { replace: true });
        }
    }, [activeTabIndex, navigate]);

    const handleTabChange = (event, newValue) => {
        if (tabs[newValue].newTab) {
            window.open(tabs[newValue].path, "_blank"); // Open in new tab
        } else if (!tabs[newValue].dropdown) {
            navigate(tabs[newValue].path);
        }
    };

    return (
        <Box>
            <AppBar position="static">
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    {/* <Typography variant="h6">Admin Dashboard</Typography> */}

                    <Tabs
                        value={activeTabIndex !== -1 ? activeTabIndex : 0}
                        onChange={handleTabChange}
                        textColor="inherit"
                        indicatorColor="secondary"
                        sx={{ flexGrow: 1, ml: 3 }}
                    >
                        {tabs.map((tab, index) =>
                            tab.newTab ? (
                                <Tab
                                    key={index}
                                    label={tab.label}
                                    onClick={() =>
                                        window.open(tab.path, "_blank")
                                    }
                                />
                            ) : tab.dropdown ? (
                                <Tab
                                    key={index}
                                    label={
                                        <Box display="flex" alignItems="center">
                                            {tab.label}
                                            <ArrowDropDownIcon />
                                        </Box>
                                    }
                                    onClick={
                                        tab.label === "Display Options"
                                            ? handleMenuOpenDisplay
                                            : handleMenuOpenQueue
                                    }
                                />
                            ) : (
                                <Tab key={index} label={tab.label} />
                            )
                        )}
                    </Tabs>

                    {/* Dropdown Menu for "Display Options" */}
                    <Menu
                        anchorEl={anchorElDisplay}
                        open={Boolean(anchorElDisplay)}
                        onClose={handleMenuClose}
                    >
                        {tabs
                            .find((tab) => tab.label === "Display Options")
                            ?.options.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() =>
                                        handleDropdownClick(option.path)
                                    }
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                    </Menu>

                    {/* Dropdown Menu for "Queue NR" */}
                    <Menu
                        anchorEl={anchorElQueue}
                        open={Boolean(anchorElQueue)}
                        onClose={handleMenuClose}
                    >
                        {tabs
                            .find((tab) => tab.label === "Queue NR DISPLAY OPTIONS")
                            ?.options.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() =>
                                        handleDropdownClickQueueNR(option.path)
                                    }
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                    </Menu>

                    <Typography
                        sx={{ mr: 2, color: "#007BFF", fontWeight: "bold" }}
                    >
                        Logout
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Content Area */}
            <Box sx={{ p: 3 }}>
                {tabs[activeTabIndex]?.component || <Panel />}
            </Box>
        </Box>
    );
};

export default AdminHome;
