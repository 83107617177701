import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";

const ReportCard = ({ title, onClick }) => {
    return (
        <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    {title}
                </Typography>
                <Button variant="contained" onClick={onClick}>
                    View Report
                </Button>
            </CardContent>
        </Card>
    );
};

export default ReportCard;
