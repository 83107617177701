import React from "react";
import FMBLogo from "../../../assests/FMBlogo.png"; // Import the image

const Display = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column", // Stack items vertically
                height: "100vh", // Full viewport height to center everything
            }}
        >
            <img
                src={FMBLogo} // Use the imported image path
                alt="FMB Logo"
                style={{
                    width: "28%",
                    transition: "opacity 1s ease-in-out",
                    marginBottom: "50px", // Space between the image and the number
                }}
            />
            <div
                style={{
                    fontSize: "140px", // Larger font size
                    marginTop: "-80px",
                }}
            >
                <h1>15</h1> {/* The token number */}
            </div>
        </div>
    );
};

export default Display;
