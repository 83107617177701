import React, { useState, useEffect } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    colors,
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt"; // Example Icon
import axios from "axios";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AlarmIcon from "@mui/icons-material/Alarm";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


const PanelContent = () => {
    const [faqs, setFaqs] = useState([]);

    //     useEffect(() => {
    //         axios
    //             .get("http://localhost:8000/api/faqs")
    //             .then((response) => {
    //                 setFaqs(response.data);
    //             })
    //             .catch((error) => {
    //                 console.error("Error fetching FAQs:", error);
    //             });
    //     }, []);
    //    console.log(faqs, "faqs");

    // Card data
    const cardData = [
        {
            title: "Current Waiting Customers",
            value: "12",
            icon: <GroupIcon />,
        },
        {
            title: "Total Number of Customers",
            value: "21",
            icon: <GroupsIcon />,
        },
        {
            title: "Number of Self-Registered Customers",
            value: "12",
            icon: <PersonAddIcon />,
        },
        {
            title: "Number of Direct Assisted Customers",
            value: "87",
            icon: <PersonAddIcon />,
        },
        {
            title: "Number of Manually Registered Customers",
            value: "4",
            icon: <EditIcon />,
        },
        { title: "Number of Lost Customers", value: "2", icon: <EditIcon /> },
        { title: "Average Wait Time", value: "3", icon: <AccessTimeFilledIcon /> },
        { title: "Longest Wait Time", value: "2", icon: <AlarmIcon /> },
        { title: "Maximum Waiting Queue", value: "8", icon: <EditIcon /> },
    ];

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={3}>
                {cardData.map((card, index) => (
                    <Grid
                        item
                        key={index}
                        xs={12} // Full width on small screens
                        sm={6} // Half width on medium screens
                        md={3} // One-third width on large screens
                    >
                        <Card
                            sx={{
                                backgroundColor: "#00acc1", // Teal color
                                color: "white",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                p: 2,
                                boxShadow: 3,
                            }}
                        >
                            {/* Left Section */}
                            <CardContent sx={{ p: 0 }}>
                                <Typography
                                    variant="h3"
                                    sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                    {card.value}
                                </Typography>
                                <Typography variant="body1">
                                    {card.title}
                                </Typography>
                            </CardContent>

                            {/* Right Section (Icon) */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "auto",
                                }}
                            >
                                {React.cloneElement(card.icon, {
                                    sx: { fontSize: "5rem",color:'black',opacity: 0.2 },
                                })}
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PanelContent;
