import React, { useState, useEffect } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(""); // For displaying error messages
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            console.log("Token found, navigating to /home...");
            navigate("/home", { replace: true });
        }
    }, []);



    const handleLogin = async () => {
        const baseURL = "https://customer-api.theloginhub.eu/";
        const clientId = "KjBW2Nlg0wLxPbYPxv9BNpQCTAz";
        const loginMethod = "sms";
        const redirectURI = `${window.location.origin}/verify-admin`;

        const loginURL = `${baseURL}?client_id=${clientId}&login_method=${loginMethod}&redirect_uri=${encodeURIComponent(
            redirectURI
        )}`;

        window.location.href = loginURL;
    };

    const handleLoginEmail = async () => {
        const baseURL = "https://customer-api.theloginhub.eu/";
        const clientId = "KjBW2Nlg0wLxPbYPxv9BNpQCTAz";
        const loginMethod = "email";
        const redirectURI = `${window.location.origin}/verify-admin`;

        const loginURL = `${baseURL}?client_id=${clientId}&login_method=${loginMethod}&redirect_uri=${encodeURIComponent(
            redirectURI
        )}`;

        window.location.href = loginURL;
    };

    const handleSubmitLogin = async () => {
        if (!username || !password) {
            setError("Username and Password are required");
            alert("Username and Password are required");
            return;
        }

        try {
            // Replace with your backend API URL
            const response = await axios.post(
                "http://localhost:8000/api/login",
                {
                    username,
                    password,
                }
            );
            console.log(typeof(response.data.success));
            if (response.data.success === true) {
                alert("Login successful!");
                // Store token or user info if needed
                localStorage.setItem("token", response.data.user.id);
                // navigate("/home", { replace: true });
                window.location.href = "/home";
            } else {
                setError("Invalid username or password");
                alert("Invalid username or password");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle unauthorized response
                setError("Invalid username or password");
                alert("Invalid username or password");
            } else {
                setError("An error occurred. Please try again.");
                console.error("Error:", error);
            }
        }
    };

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f5f5f5",
            }}
        >
            {/* Login Box */}
            <Box
                sx={{
                    width: { xs: "90%", sm: "400px" },
                    p: 4,
                    bgcolor: "white",
                    boxShadow: 3,
                    borderRadius: 2,
                    textAlign: "center",
                }}
            >
                {/* Heading */}
                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                    Easy Que Admin
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, color: "gray" }}>
                    Welcome back! Please login to continue.
                </Typography>
                {/* Username Field */}
                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="password"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {/* Password Field */}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ py: 1.5, fontSize: "1rem", mb: 2 }}
                    onClick={handleSubmitLogin}
                >
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={handleLogin}
                    sx={{ py: 1.5, fontSize: "1rem", mb: 2 }}
                >
                    Sign in with Mobile Number
                </Button>

                {/* Login Button */}
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={handleLoginEmail}
                    sx={{ py: 1.5, fontSize: "1rem" }}
                >
                    Sign in with Email
                </Button>
            </Box>
        </Box>
    );
};

export default Login;
