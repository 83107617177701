import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FMBlogo from "../../../assests/FMBlogo.png";

const options = [
    "Gulv/Laminat/Tepper",
    "Mal Proff",
    "Klikk & Hent",
    "Tapet/Gardiner/Solskjerming",
    "Fargeveiledning",
    "Blande maling (har kode)",
    "Andre produkter",
];

const SelectionPage = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();

    const handleSelect = (option) => {
        setSelectedOption(option);
    };

    const handleNext = () => {
        // if (selectedOption) {
            navigate('/home/Next_customer-view');
        // }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 10 }}
        >
            <Typography variant="h4" gutterBottom>
                {/* FARGERIKE Majorstuen */}
                <img src={FMBlogo} width={300} />
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Vi har i bare en kø, men om vi vet hva du trenger hjelp med kan
                vi kanskje bedre hjelpe deg. Du kan velge flere tjenester.
            </Typography>
            <Box width="350px">
                {options.map((option, index) => (
                    <Button
                        key={index}
                        variant={
                            selectedOption === option ? "contained" : "outlined"
                        }
                        fullWidth
                        sx={{ marginBottom: 1 }}
                        onClick={() => handleSelect(option)}
                    >
                        {option}
                    </Button>
                ))}
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                //disabled={!selectedOption}
                sx={{ marginTop: 2 }}
            >
                Neste
            </Button>
            <Box sx={{ mt: 2,textAlign: "center" }}>
                <Typography>
                    <Link>easyQue</Link>(c) by Digifront
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <Link>sales@digifront.biz</Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default SelectionPage;
