import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const importAll = (r) => r.keys().map(r);
const images = importAll(
    require.context("../../../assests/Potrait", false, /\.(png|jpg|jpeg|webp)$/)
);

const PotraitCarousel = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",  // Ensure the container takes full width
                height: "auto", // Remove fixed 100vh height, let it scale naturally
                maxHeight: "80vh", // Limit the height for better scaling
                margin: "auto",  // Center horizontally
                marginTop:"80px"
            }}
        >
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={30}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                loop={true}
            >
                {images.map((img, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={img}
                            alt={`Portrait ${index + 1}`}
                            style={{
                                width: "48%",  // Make image take full width of the slide
                                height: "auto", // Keep aspect ratio intact
                                objectFit: "cover", // Ensure image covers the area without distortion
                                display: "block",  // Remove any unwanted space around the image
                                margin: "0 auto", // Center the image

                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default PotraitCarousel;
