import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { styled } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from "@mui/material";

// ✅ Styled Table Components for better UI
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&:nth-of-type(odd)`]: {
        backgroundColor: theme.palette.action.hover,
    },
    textAlign: "center",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
}));

// ✅ Sample Data (Replace this with API data)
const timeSlots = ["06:00-08:00", "08:00-10:00", "10:00-12:00", "12:00-14:00", "14:00-16:00", "16:00-18:00", "18:00-20:00"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const customerData = {
    Sunday: [2, 2, 1, 1, 1, 1, 1],
    Monday: [3, 5, 5, 6, 7, 9, 2],
    Tuesday: [3, 5, 5, 5, 6, 9, 3],
    Wednesday: [2, 4, 5, 6, 5, 10, 2],
    Thursday: [3, 4, 5, 4, 6, 8, 4],
    Friday: [3, 5, 5, 6, 7, 7, 5],
    Saturday: [1, 4, 9, 13, 14, 4, 1],
};

const Customer_Hour_Report = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        // ✅ Format data for ECharts
        const seriesData = days.map((day) => ({
            name: day,
            type: "bar",
            data: customerData[day],
        }));

        // ✅ Define Chart Options
        const options = {
            title: {
                text: "Average Customer Count (2-Hour Slots)",
                left: "center",
                textStyle: { fontSize: 18, fontWeight: "bold" },
            },
            tooltip: { trigger: "axis" },
            legend: { bottom: 0 },
            xAxis: {
                type: "category",
                data: timeSlots,
                axisLabel: { rotate: 30 },
            },
            yAxis: { type: "value" },
            series: seriesData,
        };

        // Render Chart
        chartInstance.setOption(options);

        return () => chartInstance.dispose();
    }, []);

    return (
        <Box sx={{ p: 3 }}>
            {/* ✅ Table Section */}
            <Typography variant="h5" align="center" sx={{ fontWeight: "bold", mb: 2 }}>
                Average Customer Count (2-Hour Slots)
            </Typography>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Day / Time Slot</StyledTableCell>
                            {timeSlots.map((slot) => (
                                <StyledTableCell key={slot}>{slot}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {days.map((day) => (
                            <StyledTableRow key={day}>
                                <StyledTableCell>{day}</StyledTableCell>
                                {customerData[day].map((count, index) => (
                                    <StyledTableCell key={index}>{count}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ✅ Chart Section */}
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Customer Count Trend by Time Slots
            </Typography>
            <Box
                ref={chartRef}
                sx={{
                    width: "100%",
                    height: "400px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: 2,
                    backgroundColor: "#fff",
                }}
            />
        </Box>
    );
};

export default Customer_Hour_Report;
