import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios

const VerifyAdmin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const verifyMobileLogin = async () => {
            console.log("Verifying Mobile Login...");
            const queryParams = new URLSearchParams(window.location.search);
            const user = queryParams.get("user"); // Get mobile number or email from URL

            if (user) {
                try {
                    const { data } = await axios.post(
                        "http://localhost:8000/api/verify-mobile-login",
                        { user }
                    );

                    if (data.status) {
                        console.log("Login successful!");
                        localStorage.setItem("token", data.user.id);

                        // Redirect to dashboard
                        setTimeout(() => {
                            console.log("Navigating to dashboard...");
                            window.location.href = "/home"; // More reliable redirect
                        }, 300);
                    } else {
                        alert(data.message); // Show error if user not found
                        navigate("/"); // Redirect back to login
                    }
                } catch (error) {
                    console.error("Error verifying mobile login:", error);
                    alert("User not found. Please register.");
                    navigate("/");
                }
            } else {
                alert("Invalid login response.");
                navigate("/");
            }
        };

        verifyMobileLogin();
    }, [navigate]);

    return <h2>Verifying Mobile Login...</h2>;
};

export default VerifyAdmin;
