import React, { useEffect, useState } from "react";
import {
    Button,
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Staff = () => {
    const navigate = useNavigate();
    const [queues, setQueues] = useState([]); // Store queue data

    // Fetch data from API
    useEffect(() => {
        axios
            .get("http://localhost:8000/api/queues") // Change API URL
            .then((response) => setQueues(response.data))
            .catch((error) => console.error("Error fetching queues:", error));
    }, []);

    return (
        <Box sx={{ p: 3 }}>
            {/* Top Section: Company Name & Dashboard Button */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 3 }}
            >
                {/* <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#007BFF" }}
                >
                    LOCATION: FARGERIKE MAJORSTUEN
                </Typography> */}
                <Button variant="contained">
                    LOCATION: FARGERIKE MAJORSTUEN
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/home")}
                >
                    ADMIN
                </Button>
            </Box>

            {/* Cards Section */}
            <Grid container spacing={3}>
                {/* No Queue Card (Always Visible) */}
                <Grid item xs={12} sm={6} md={3}>
                    <Card
                        sx={{
                            textAlign: "center",
                            p: 2,
                            boxShadow: 3,
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h7"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    NO CUSTOMERS WAITING
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "green",
                                        color: "white",
                                    }}
                                >
                                    ASSIST
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Dynamic Cards from Database */}
                {queues.map((queue, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", color: "#333" }}
                                >
                                    {queue.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ color: "#666", mb: 2 }}
                                >
                                    {queue.description}
                                </Typography>
                                <Button variant="contained" color="primary">
                                    Assist
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Staff;
