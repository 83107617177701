import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import FMBlogo from "../../../assests/FMBlogo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const importAll = (r) => r.keys().map(r);
const images = importAll(
    require.context(
        "../../../assests/landscape",
        false,
        /\.(png|jpg|jpeg|webp)$/
    )
);

const QueueDisplay = () => {
     const [currentIndex, setCurrentIndex] = useState(0);

        useEffect(() => {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 5000); // Change image every 5 seconds

            return () => clearInterval(interval);
        }, []);
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
        >
            {/* Logo and Branding */}
            <img src={FMBlogo} width={300} />
            <Typography variant="subtitle1">Majorstuen</Typography>
            <Typography variant="h6" fontWeight="bold" mt={2}>
                Ditt kønummer er
            </Typography>

            {/* Queue Number */}
            <Typography variant="h1" fontWeight="bold">
                01
            </Typography>

            {/* Waiting Time Message */}
            <Typography variant="body1" mt={1} textAlign="center">
                Vi hjelper deg gjerne i gang opptil 10 min.
            </Typography>

            {/* Service Recommendation */}
            <Typography
                variant="body2"
                mt={1}
                textAlign="center"
                maxWidth={500}
            >
                Mange ønsker hjelp med Fargeveiledning. Vi anbefaler at du
                booker en time med oss til kun kr. 690,- for 45 min med
                personlig veiledning, fargepalett og interiørplan.
            </Typography>

            {/* Promotional Banner */}
            <Box mt={3} width={1000}>
            <img
                src={images[currentIndex]}
                alt={`Landscape ${currentIndex + 1}`}
                style={{ width: "90%", transition: "opacity 1s ease-in-out" }}
            />
            </Box>

            {/* Footer */}
            <Typography variant="body2" color="textSecondary" mt={3}>
                easyQue (c) by Digifront
            </Typography>
            <Typography variant="body2" color="primary">
                sales@digifront.biz
            </Typography>
        </Box>
    );
};

export default QueueDisplay;
