import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ✅ Styled Components for the Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontWeight: "bold",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
       textAlign: "center",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
}));

// ✅ Sample Data (You can replace it with API data)
const rows = [
    { name: "Sunday", value: 159 },
    { name: "Monday", value: 237 },
    { name: "Tuesday", value: 262 },
    { name: "Wednesday", value: 305 },
    { name: "Friday", value: 156 },
    { name: "Saturday", value: 256 },
];

const Customer_Day_Report = () => {
    const chartRef = useRef(null); // Reference for the chart div

    useEffect(() => {
        // Initialize ECharts
        const chartInstance = echarts.init(chartRef.current);

        // ✅ Use Table Data for the Chart
        const categories = rows.map((row) => row.name);
        const values = rows.map((row) => row.value);

        // ✅ Define Chart Options
        const options = {
            title: {
                text: "Dessert Consumption Chart",
                left: "center",
                textStyle: { fontSize: 16, fontWeight: "bold" },
            },
            tooltip: { trigger: "axis" },
            xAxis: {
                type: "category",
                data: categories,
                axisLabel: { rotate: 30 }, // Rotate labels for better visibility
            },
            yAxis: { type: "value" },
            series: [
                {
                    data: values,
                    type: "bar",
                    barWidth: "50%",
                    itemStyle: {
                        color: "#007BFF", // Custom bar color
                    },
                },
            ],
        };

        // Set options
        chartInstance.setOption(options);

        // Cleanup on unmount
        return () => {
            chartInstance.dispose();
        };
    }, []);

    return (
        <Box sx={{ p: 2 }}>
            {/* ✅ Table Section */}
            <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Day</StyledTableCell>
                            <StyledTableCell align="right">Average Customer Count</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell align="right">{row.value}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ✅ Chart Section */}
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Dessert Consumption Chart
            </Typography>
            <Box
                ref={chartRef}
                sx={{
                    width: "100%",
                    height: "400px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: 2,
                    backgroundColor: "#fff",
                }}
            />
        </Box>
    );
};

export default Customer_Day_Report;
