import React, { useState, useEffect } from "react";
//import  landscape from '../../../assests/landscape';

// Dynamically import all images in the folder
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context("../../../assests/landscape", false, /\.(png|jpg|jpeg|webp)$/));

const Landscape = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <img
                src={images[currentIndex]}
                alt={`Landscape ${currentIndex + 1}`}
                style={{ width: "90%", transition: "opacity 1s ease-in-out" }}
            />
        </div>
    );
};

export default Landscape;
