import React from "react";
import { Box, Typography } from "@mui/material";
import img from "../../../assests/images.png";
import FMBlogo from "../../../assests/FMBlogo.png";

const QRPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#fff"
    >
      {/* Heading */}
      <Typography variant="h1" fontWeight="bold" gutterBottom>
        KØLAPP
      </Typography>

      {/* Logo & Text */}
      <Typography variant="h5" color="textSecondary" gutterBottom>
        <img src={FMBlogo} width={300} alt="FMB Logo" />
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Majorstuen
      </Typography>

      {/* QR Code */}
      <Box mt={3}>
        <img src={img} width={600} alt="QR Code" />
      </Box>
    </Box>
  );
};

export default QRPage;
