import React, { useState, useEffect } from "react";

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context("../../../assests/Potrait", false, /\.(png|jpg|jpeg|webp)$/));

const Potrait = () => {
         const [currentIndex, setCurrentIndex] = useState(0);


     useEffect(() => {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 5000); // Change image every 5 seconds

            return () => clearInterval(interval);
        }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
               src={images[currentIndex]}
               alt={`Potrait ${currentIndex + 1}`}
               style={{ width: "28%", transition: "opacity 1s ease-in-out" }}
            />
        </div>
    );
};

export default Potrait;
