import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context("../../../assests/landscape", false, /\.(png|jpg|jpeg|webp)$/));

const Carousel = () => {
    return (
        <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            loop={true}
        >
            {images.map((img, index) => (
                <SwiperSlide key={index}>
                    <img src={img} alt={`Landscape ${index + 1}`} style={{ width: "90%",  objectFit: "cover" }} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Carousel;
